<template>
  <div>
    <primaryTextfield
      :value="value"
      :fieldAttributes="fieldAttributes"
      :fieldAttrInput="{disabled:disabled}"
      v-on="$listeners"
    ></primaryTextfield>
  </div>
</template>

<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";

export default {
  components: {
    primaryTextfield,
  },
  props: ["result", "fieldAttributes", "field","value"],
  computed: {
    disabled() {
      let disabled = false;
      if (this.result.valueLine.type == "output") {
        disabled = true;
        // this.$emit("input", "");
      }
      return disabled;
    },
  },
};
</script>